import React from 'react'
import { useDispatch } from 'react-redux'

import Link from 'next/link'
import Image from 'next/image'
import { useRouter } from 'next/router'
import MobileMenu from '../mobileMenu'

export default function Header({ selectedMenu, engURL = '', esURL = '', data, viewport }) {
  const router = useRouter()
  const dispatch = useDispatch()

  const [menuOpen, setMenuOpen] = React.useState(false)

  const handleCloseMenuModal = () => {
    setMenuOpen(!menuOpen)
  }

  const menus = [
    {
      title: 'HOME',
      link: '/',
    },
    {
      title: data.features[router.locale],
      link: '/#features',
    },
    {
      title: data.blog[router.locale],
      link: `/${router.locale}/blog`,
    },
    {
      title: data.pricing[router.locale],
      link: '/#pricing',
    },
    {
      title: data.contact[router.locale],
      link: '/#contact',
    },
  ]

  return (
    <div className={'w-full flex header'}>
      <div className={`${viewport === 'desktop' ? '' : 'w-1/5'} flex flex-wrap items-center`}>
        <a href={'/'} className={'flex flex-wrap items-center'}>
          <Image
            src={'/images/logo.png'}
            width={viewport === 'desktop' ? 123 : 75}
            height={viewport === 'desktop' ? 33 : 20}
            className={'logo'}
            alt=""
          />
        </a>
      </div>

      <div className={`${viewport === 'desktop' ? 'w-full' : 'w-1/5'} flex flex-wrap justify-end`}>
        {viewport === 'desktop' && (
          <ul className="flex flex-col sm:flex-row list-none items-center justify-end">
            {menus &&
              menus.map((menu, key) => {
                return (
                  <li
                    className={`flex justify-center menu-item ${selectedMenu === menu.title ? 'active' : ''}`}
                    key={key}
                  >
                    <button name={menu.title} onClick={() => dispatch({ type: 'set', menu: menu.title })}>
                      <Link href={menu.link} locale={router.locale}>
                        <a>{menu.title}</a>
                      </Link>
                    </button>
                  </li>
                )
              })}
          </ul>
        )}
        {viewport === 'desktop' ? (
          <></>
        ) : (
          <React.Fragment>
            <MobileMenu
              menus={menus}
              open={menuOpen}
              selectedMenu={selectedMenu}
              onClose={() => handleCloseMenuModal()}
            />
          </React.Fragment>
        )}
      </div>
      <div className={`${viewport === 'desktop' ? `w-1/5` : `w-2/5`} flex idioma`}>
        <ul>
          <li>
            <Link href={{ pathname: `/en/${engURL}`, query: router.query }} locale="en">
              <a>{router.locale === 'en' ? <strong>ENG</strong> : 'ENG'}</a>
            </Link>
          </li>
          <li> | </li>
          <li>
            <Link href={{ pathname: `/es/${esURL}`, query: router.query }} locale="es">
              <a>{router.locale === 'es' ? <strong>ESP</strong> : 'ESP'}</a>
            </Link>
          </li>
        </ul>
      </div>

      <div className={`${viewport === 'desktop' ? '' : 'w-1/5'} flex flex-wrap justify-center`}>
        {viewport === 'mobile' && (
          <button className={'hamburg-menu'} onClick={() => handleCloseMenuModal()}>
            <svg width="20px" height="16px" viewBox="0 0 20 16" version="1.1">
              <g id="Pagina" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Mobile-landing" transform="translate(-19.000000, -44.000000)" fill="#030303">
                  <g id="icon/menu" transform="translate(19.000000, 44.000000)">
                    <g
                      id="Group-2"
                      transform="translate(10.000000, 8.000000) scale(1, -1) translate(-10.000000, -8.000000) "
                    >
                      <rect
                        id="Rectangle-228"
                        transform="translate(10.000000, 0.750000) scale(-1, -1) translate(-10.000000, -0.750000) "
                        x="0"
                        y="0"
                        width="20"
                        height="1.5"
                        rx="0.75"
                      />
                      <rect
                        id="Rectangle-228-Copy"
                        transform="translate(7.000000, 7.750000) scale(-1, -1) translate(-7.000000, -7.750000) "
                        x="0"
                        y="7"
                        width="14"
                        height="1.5"
                        rx="0.75"
                      />
                      <rect
                        id="Rectangle-228-Copy-2"
                        transform="translate(10.000000, 14.750000) scale(-1, -1) translate(-10.000000, -14.750000) "
                        x="0"
                        y="14"
                        width="20"
                        height="1.5"
                        rx="0.75"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </button>
        )}
      </div>
    </div>
  )
}
