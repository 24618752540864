import React from 'react'
import { useRouter } from 'next/router'

export default function Footer({ viewport }) {
  const router = useRouter()

  const year = new Date()
  const [mounted, setMounted] = React.useState(false);
  React.useEffect(() => {
    setMounted(true)
  }, [])

  return mounted && (
    <React.Fragment>
      <footer className={`w-full flex flex-wrap justify-center footer`}>
        <div className={'w-full mt-16 grid grid-cols-4 gap-4 social-icons-content'}>
          <div className={'w-full flex flex-wrap justify-center'}>
            <div className={'social-item'}>
              <div className={'icon-facebook'}>
                <a href="https://www.facebook.com/PLAYBAKVideo/">
                  <i className="fab fa-facebook-f"></i>
                </a>
              </div>
            </div>
          </div>

          <div className={'w-full flex flex-wrap justify-center'}>
            <div className={'social-item'}>
              <div className={'icon-twitter'}>
                <a href="https://twitter.com/playbakvideo">
                  <i className="fab fa-twitter"></i>
                </a>
              </div>
            </div>
          </div>

          <div className={'w-full flex flex-wrap justify-center'}>
            <div className={'social-item'}>
              <div className={'icon-instagram'}>
                <a href="https://www.instagram.com/playbakvideo/">
                  <i className="fab fa-instagram"></i>
                </a>
              </div>
            </div>
          </div>

          <div className={'w-full flex flex-wrap justify-center'}>
            <div className={'social-item'}>
              <div className={'icon-linkedin'}>
                <a href="https://www.linkedin.com/company/playbak">
                  <i className="fab fa-linkedin-in"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
        <p className={'w-full flex flex-wrap copyright justify-center'}>
          <span className={`year`}> &copy; {year.getFullYear()} Play</span>
          <span className={'strong'}>BAK </span> &nbsp; {' All Right Reserved'}
        </p>
        <p className={'w-full flex flex-wrap politica justify-center'}>
          <span>
            <a href="https://playbak.io/legal/privacy-policy" rel="noreferrer" target="_blank">
              {router.locale === 'es' ? 'Política de Privacidad' : 'Privacy Policy'}
            </a>
          </span>
          <span>
            <a href="https://playbak.io/legal/cookie-policy" rel="noreferrer" target="_blank">
              {router.locale === 'es' ? 'Política de Cookies' : 'Cookies policy'}
            </a>
          </span>
          <span>
            <a href="https://playbak.io/legal/condiciones-de-uso" rel="noreferrer" target="_blank">
              {router.locale === 'es' ? 'Condiciones de Uso' : 'Terms of use'}
            </a>
          </span>
        </p>
      </footer>
    </React.Fragment>
  )
}
